import * as React from 'react'
// import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby';
import { TopNav } from '@bestfit-team/bf-base-ui';
import { GlobalBodyStyles } from '@bestfit-team/bf-base-styles';
// import { GlobalBodyStyles, TopNav } from '@bestfit-team/bf-base-ui';
import * as styles from '../override-styles.css';
const Layout = ({ pageTitle, children }) => {
  const copy = useStaticQuery(graphql`
    query {
      desktopQuery: allAirtableAllPagesTable(
        sort: {fields: data___Top_Nav_Position}
        filter: {data: {Top_Nav_Position: {ne: null}}}
      ) {
        nodes {
          data {
            Top_Nav_Position
            slug
            title
          }
        }
      }

      mobileQuery: allAirtableAllPagesTable(
        sort: {fields: data___Mobile_Nav_Position}
        filter: {data: {Mobile_Nav_Position: {ne: null}}}
      ) {
        nodes {
          data {
            Mobile_Nav_Position
            slug
            title
          }
        }
      }
    }
  `);


  return (
    <>
      <GlobalBodyStyles />
      <title>{pageTitle}</title>
      <TopNav menuInfo={copy.desktopQuery.nodes} mobileMenuInfo={copy.mobileQuery.nodes} />
      <main>
        {/* <h1>{pageTitle}</h1> */}
        {children}
      </main>
    </>
  )
}
export default Layout